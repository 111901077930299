import AnnouncementBar from "./AnnouncementBar";
import Landing from "./Landing";
import Vision from "./Vision";
import Technologies from "./Technologies";
import Projects from "./Projects";
import Client from "./Client";
import Team from "./Team";
import Info from "./Info";
import Footer from "./Footer";

const MainPage = () => {
  return (
    <div className="App">
      <AnnouncementBar />
      <Landing />
      <Vision />
      <Technologies />
      <Projects />
      <Client />
      <Team />
      <Info />
      <Footer />
    </div>
  );
};

export default MainPage;
