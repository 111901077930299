import React from "react";
import { useState, useEffect } from "react";
//import caret from react-icons
import {
  FaChevronRight,
  FaChevronLeft,
  FaCircleCheck,
  FaCircleExclamation,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

import Select from "react-select";
import "animate.css";
import { HashLoader } from "react-spinners";

const ApplicationPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [year, setYear] = useState("");
  const [major, setMajor] = useState("");
  const [essay, setEssay] = useState("");
  const [channel, setChannel] = useState("");
  const [showModal, setShowModal] = useState(0);
  const [modalMessage, setModalMessage] = useState(
    "Submitting your information, hang tight..."
  );
  const [pronouns, setPronouns] = useState("");
  const [experience, setExperience] = useState("");
  const handleSubmit = () => {
    //scroll page to top smoothly
    window.scrollTo({ top: 300, behavior: "smooth" });

    //validation that all fields are filled out
    if (name == "" || email == "") {
      return;
    } else {
      //show modal
      setShowModal(1);

      //loop through projects, and add the values to a string

      let channelString = "";
      for (let i = 0; i < channel.length; i++) {
        channelString += channel[i].value + ", ";
      }

      const combinedBody = JSON.stringify({
        name: name,
        email: email,
        year: year,
        major: major,
        essay: essay,
        channel: channelString,
        pronouns: pronouns,
        experience: experience,
      });

      console.log(combinedBody);

      fetch("/api/submitApplication", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: combinedBody,
      })
        .then((res) => {
          if (res.status == 200) {
            return res.json();
          } else {
            throw new Error(
              "We encountered an unexpected error. Please try again later."
            );
          }
        })
        .then(() => {
          setModalMessage("Success! You're in!");
          setShowModal(2);
        })
        .catch((err) => {
          console.log(err);
          setModalMessage("Whoops!");
          setShowModal(3);
        });
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setYear("");
    setMajor("");
    setEssay("");
    setPronouns("");
    setExperience("");
    setShowModal(0);
    setModalMessage("Submitting your application, hang tight...");
    setChannel("");
  };

  return (
    <div className="application-page">
      <Link to="/" className="application-page-back">
        <FaChevronLeft />
        <p>Back to Website</p>
      </Link>
      <img
        src="svgs/application-top-image.svg"
        className="application-top-image"
      />
      <img
        src="svgs/application_bottom.svg"
        className="application-bottom-image"
      />

      <div className="application-page-container">
        <div className="application-page-left">
          <h4>JOIN NOW</h4>
          <h1>Hoya Developers</h1>
          <p>
            We're thrilled for you to join our community! Fill out this form
            before 5pm on Monday, January 27th to reserve your spot in Hoya Devs
            Bootcamp!
          </p>
        </div>
        <div className="application-page-right">
          {showModal !== 0 && (
            <div className="application-page-modal animate__animated animate__fadeIn">
              <div className="application-page-modal-content">
                {showModal == 1 && (
                  <HashLoader
                    color={"#86D600"}
                    loading={true}
                    size={50}
                    speedMultiplier={1}
                  />
                )}
                {showModal == 2 && (
                  <FaCircleCheck className="application-page-modal-check" />
                )}
                {showModal == 3 && (
                  <FaCircleExclamation className="application-page-modal-check" />
                )}
                <h3>{modalMessage}</h3>
                {showModal == 2 && (
                  <div>
                    <p>
                      Thank you, {name}! Be on the lookout over the next couple
                      of weeks for more information from us about Bootcamp. We
                      can't wait for you to get started!
                    </p>
                    <p>
                      If you ever have any questions, please feel free to reach
                      out at hoyadevelopers@gmail.com - we are happy to help!
                    </p>
                    <button onClick={() => resetForm()}>Close</button>
                  </div>
                )}
                {showModal == 3 && (
                  <div>
                    <p>
                      We encountered an unexpected error. Please try again
                      later.
                    </p>
                    <button onClick={() => resetForm()}>Close</button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="application-page-container-double">
            <div className="application-page-input-container">
              <h6>Your Full Name</h6>
              <div className="application-page-input">
                <FaChevronRight className="application-page-input-caret" />
                <input
                  type="text"
                  placeholder="Your Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="application-page-input-container">
              <h6>Your Email</h6>
              <div className="application-page-input">
                <FaChevronRight className="application-page-input-caret" />
                <input
                  type="text"
                  placeholder="Your Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
          </div>
          <div className="application-page-container-double">
            <div className="application-page-input-container">
              <h6>Class Year</h6>
              <div className="application-page-input">
                <FaChevronRight className="application-page-input-caret" />
                <Select
                  unstyled
                  value={year != "" ? { value: year, label: year } : null}
                  className="application-page-select"
                  options={[
                    { value: "2025", label: "2025" },
                    { value: "2026", label: "2026" },
                    { value: "2027", label: "2027" },
                    { value: "2028", label: "2028" },
                  ]}
                  onChange={(e) => setYear(e.value)}
                  styles={{
                    option: (base) => ({
                      ...base,
                      backgroundColor: "#222",
                      color: "#aaa",
                      padding: "10px 10px",
                      fontFamily: "Poppins",
                      "&:hover": {
                        backgroundColor: "#333",
                        cursor: "pointer",
                      },
                    }),
                    control: (base) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                      backgroundColor: "#222",
                      color: "#aaa",
                      width: "100%",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      height: "50px",
                      borderRadius: "3px",
                      padding: "0 10px",
                      cursor: "pointer",
                      "&:hover": {
                        border: 0,
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div className="application-page-input-container">
              <h6>Field of Study</h6>
              <div className="application-page-input">
                <FaChevronRight className="application-page-input-caret" />
                <Select
                  unstyled
                  className="application-page-select"
                  value={major !== "" ? { value: major, label: major } : null}
                  options={[
                    { value: "CS Major", label: "CS Major" },
                    { value: "CS Minor", label: "CS Minor" },
                    { value: "STIA", label: "STIA Major" },
                    { value: "Business Major", label: "Business Major" },
                    { value: "Undecided", label: "Undecided" },
                    { value: "Other", label: "Other" },
                  ]}
                  onChange={(e) => setMajor(e.value)}
                  styles={{
                    option: (base) => ({
                      ...base,
                      backgroundColor: "#222",
                      color: "#aaa",
                      padding: "10px 10px",
                      fontFamily: "Poppins",
                      "&:hover": {
                        backgroundColor: "#333",
                        cursor: "pointer",
                      },
                    }),
                    control: (base) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                      backgroundColor: "#222",
                      color: "#aaa",
                      width: "100%",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      height: "50px",
                      borderRadius: "3px",
                      padding: "0 10px",
                      cursor: "pointer",
                      "&:hover": {
                        border: 0,
                      },
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="application-page-container-double">
            <div className="application-page-input-container">
              <h6>Pronouns</h6>
              <div className="application-page-input">
                <FaChevronRight className="application-page-input-caret" />
                <input
                  type="text"
                  placeholder="Your Pronouns"
                  onChange={(e) => setPronouns(e.target.value)}
                  value={pronouns}
                />
              </div>
            </div>
            <div className="application-page-input-container">
              <h6>Experience Level</h6>
              <div className="application-page-input">
                <FaChevronRight className="application-page-input-caret" />
                <Select
                  unstyled
                  className="application-page-select"
                  value={
                    experience !== ""
                      ? { value: experience, label: experience }
                      : null
                  }
                  options={[
                    { value: "1", label: "1. I am brand-new to coding!" },
                    {
                      value: "2",
                      label:
                        "2. I have taken / am taking a CS course, but don't have experience actually building websites or apps.",
                    },
                    {
                      value: "3",
                      label:
                        "3. I have built a website or app before, but I'm excited to get more experience.",
                    },
                    {
                      value: "4",
                      label:
                        "4. I consider myself to be an experienced developer.",
                    },
                  ]}
                  onChange={(e) => setExperience(e.label)}
                  styles={{
                    option: (base) => ({
                      ...base,
                      backgroundColor: "#222",
                      color: "#aaa",
                      padding: "10px 10px",
                      fontFamily: "Poppins",
                      "&:hover": {
                        backgroundColor: "#333",
                        cursor: "pointer",
                      },
                    }),
                    control: (base) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                      backgroundColor: "#222",
                      color: "#aaa",
                      width: "100%",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      height: "50px",
                      borderRadius: "3px",
                      padding: "0 10px",
                      cursor: "pointer",
                      "&:hover": {
                        border: 0,
                      },
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="application-page-input-container">
            <h6>How did you hear about us?</h6>
            <div className="application-page-input">
              <FaChevronRight className="application-page-input-caret" />
              <Select
                unstyled
                isMulti
                className="application-page-select"
                options={[
                  { value: "CAB Fair", label: "CAB Fair" },
                  { value: "Instagram", label: "Instagram" },
                  { value: "LinkedIn", label: "LinkedIn" },
                  { value: "Info Session", label: "Info Session" },
                  { value: "Club Member", label: "From a club member" },
                  { value: "In class", label: "In class" },
                ]}
                value={channel}
                onChange={(e) => setChannel(e)}
                styles={{
                  option: (base) => ({
                    ...base,
                    backgroundColor: "#222",
                    color: "#aaa",
                    padding: "10px 10px",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "#333",
                      cursor: "pointer",
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    border: 0,
                    boxShadow: "none",
                    backgroundColor: "#222",
                    color: "#aaa",
                    width: "100%",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    borderRadius: "3px",
                    padding: "0 10px",
                    cursor: "pointer",
                    "&:hover": {
                      border: 0,
                    },
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "#444",
                    color: "#aaa",
                    fontFamily: "Poppins",
                    borderRadius: "3px",
                    padding: "2px 10px",
                    margin: "0 5px",
                  }),
                }}
              />
            </div>
          </div>
          <div className="application-page-input-container">
            <h6>Your coding background/interests</h6>
            <p>
              We don't expect you to have any experience before joining.
              However, we'd love to know if there are specific technologies
              you've worked with or interested in learning more about!
            </p>
            <div className="application-page-input">
              <FaChevronRight className="application-page-input-caret" />
              <textarea
                type="text"
                placeholder="Start writing..."
                className="textarea"
                onChange={(e) => setEssay(e.target.value)}
                value={essay}
              />
            </div>
          </div>
          <input
            type="submit"
            value="Join Now!"
            className="application-submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationPage;
