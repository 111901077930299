import React from "react";

const Client = () => {
  return (
    <div className="client">
      <div className="container">
        <h6>Become A Client</h6>
        <h3>
          Looking for exceptional, pro-bono technical services? Learn how our
          partnerships work and what services we provide.
        </h3>
        <div className="client-content">
          <div className="client-content-left">
            <p>
              Our client projects run 12 weeks and will start early February. We
              work with a diverse array of businesses, non-profits, and start-up
              companies to build professional-quality websites, mobile
              applications, micro-services, and more.
            </p>
            <p>
              For your project, we assign a team of developers to build your
              solution. Each project is led by a project manager, who will work
              closely with you throughout the development of each application.
              PMs meet weekly with their clients to provide updates and receive
              feedback on the team’s work.
            </p>
            <p>
              At the end of the semester, all source code and applications are
              delivered to our clients for deployment. All projects we build are
              property of our clients.
            </p>
            <p>
              If your organization is interested in partnering with us, please
              click the button below to apply!
            </p>
            <a
              href="https://forms.gle/9puirCm1XdDWGVSX9"
              className="main-btn client-btn"
              target="_blank"
            >
              Client Application
            </a>
          </div>

          <img src="svgs/client.svg" alt="client" className="client-img" />
        </div>
      </div>
    </div>
  );
};

export default Client;
