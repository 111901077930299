//imports
import "./App.scss";
import ApplicationPage from "./components/ApplicationPage";
import CabFair from "./components/CabFair";
import MainPage from "./components/MainPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { inject } from "@vercel/analytics";
import InfoSession from "./components/InfoSession";

const App = () => {
  inject();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/join" element={<ApplicationPage />} />
        <Route path="/cab" element={<CabFair />} />
        <Route path="/info" element={<InfoSession />} />
      </Routes>
    </Router>
  );
};

export default App;
