import React from "react";

const AnnouncementBar = () => {
  return (
    <div className="announcement-bar">
      <p className="announcement-text">
        [EXTENSION] Our Spring 2025 Client Application registration is now live!
        We've extended the deadline to midnight on 1/25:{" "}
        <a
          href="https://forms.gle/9puirCm1XdDWGVSX9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Apply here
        </a>
        .
      </p>
    </div>
  );
};

export default AnnouncementBar;
